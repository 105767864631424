export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        tickets :'', 
        ticket :'', 
    },
    
    getters : {
        tickets: (state )=> state.tickets,
        ticket: (state )=> state.ticket,
    },


    mutations : { 
        settickets :(state,tickets ) => (state.tickets = tickets), 
        setticket :(state,ticket ) => (state.ticket = ticket),
    },

    actions : {

        async create_ticket( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_ticket')
            return new Promise((resolve, reject) => {
            axios.post("/admin/tickets/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settickets', response.data.res)
                toast.success("ticket Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_tickets( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_tickets')
            return new Promise((resolve, reject) => {
            axios.post("/admin/tickets?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settickets', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_ticket( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_ticket' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/tickets/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setticket', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_ticket( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_ticket' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/tickets/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settickets', response.data.res)
                toast.success("ticket Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async delete_ticket( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_ticket' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/tickets/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settickets', response.data.res)
                toast.success("ticket Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}