export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        users :'', 
        user :'', 
        profiles :'', 
        profile :'', 
    },
    
    getters : {
        users: (state )=> state.users,
        user: (state )=> state.user,
        profiles: (state )=> state.profiles,
        profile: (state )=> state.profile,
    },


    mutations : { 
        setusers :(state,users ) => (state.users = users), 
        setuser :(state,user ) => (state.user = user),
        setprofiles :(state,profiles ) => (state.profiles = profiles), 
        setprofile :(state,profile ) => (state.profile = profile),
    },

    actions : {

        async create_user( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_user')
            return new Promise((resolve, reject) => {
            axios.post("/admin/users/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setusers', response.data.res)
                toast.success("user Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_users( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_users')
            return new Promise((resolve, reject) => {
            axios.post("/admin/users?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setusers', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_profiles( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_profiles')
            return new Promise((resolve, reject) => {
            axios.post("/admin/users/profiles?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setprofiles', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        async get_user( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_user' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/users/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setuser', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_user( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_user' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/users/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setusers', response.data.res)
                toast.success("user Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async change_user_status( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_user_status' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/users/status/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setusers', response.data.res)
                toast.success("User Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async delete_user( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_user' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/users/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setusers', response.data.res)
                toast.success("user Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}