import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const secure = new SecureLs({isCompression:false});
import auth  from './modules/auth'
import utils  from './modules/utils'
import adverts  from './modules/adverts'
import communications  from './modules/communications'
import tickets from './modules/tickets';
import users from './modules/users';
import app_notifications from './modules/app_notifications';

export default createStore({
  modules: {
    auth, 
    utils,
    adverts,
    tickets,
    communications,
    users,
    app_notifications,
  },
    
  plugins: [createPersistedState(
    {
      key : 'smarttraderuganda.com',
      paths: ['auth','utils'],
      storage:{
         getItem : (key) => secure.get(key),
         setItem: (key, value) => secure.set(key, value),
         removeItem: (key) => secure.remove(key)
      }
    }
  )],
  
})
