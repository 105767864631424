import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './axios'
import './assets/app_style.css'
import loadingBtn from '@/views/comps/loadingbtn'
import dataLoader from '@/views/comps/dataloader'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { dom } from "@fortawesome/fontawesome-svg-core";
library.add(far);
library.add(fab);
library.add(fas);
dom.watch();

createApp(App).component("font-awesome-icon", FontAwesomeIcon).component('loadingBtn', loadingBtn).component('dataLoader', dataLoader).use(store).use(router).mount('#app')
