import store from "@/store";
const adminguard = (to, from, next) => {
  if(localStorage.getItem('smsadvantage' + store.getters.authuser.id) != null && store.getters.authuser.role == 'admin' ){
      return next()
  }else{
      return next({ name: 'login' })
  }
};

let routes = [
  {
    path: "/",
    props: true,
    beforeEnter: adminguard,
    component: () => import("@/views/app/main.vue"),
    title: "",

    children: [
      {
        path: "",
        name: "welcome",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/dash.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },

      {
        path: "adverts",
        name: "",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/adverts/main.vue"),
        children: [
          {
            path: "",
            name: "adverts",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/adverts/adverts.vue"),
            meta: {
              title: "Manage Ongoing Orders and Create New ones",
            },
          },
          {
            path: ":id",
            name: "advert",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/adverts/advert.vue"),
            meta: {
              title: "Manage Ongoing Orders and Create New ones",
            },
          },
        ],
      },
      {
        path: "tickets",
        name: "",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/tickets/main.vue"),
        children: [
          {
            path: "",
            name: "tickets",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/tickets/user_tickets.vue"),
            meta: {
              title: "Manage Ongoing Orders and Create New ones",
            },
          },
          {
            path: ":id",
            name: "ticket_detail",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/tickets/tickets_detail.vue"),
            meta: {
              title: "Manage Ongoing Orders and Create New ones",
            },
          },
          
        ],
      },

      {
        path: "communications",
        name: "",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/communications/main.vue"),
        children: [
          {
            path: "",
            name: "communications",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/communications/sent.vue"),
            meta: {
              title: "Manage Ongoing Orders and Create New ones",
            },
          },
        ],
      },
      {
        path: "profiles",
        name: "",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/profiles/main.vue"),
        children: [
          {
            path: "",
            name: "app_users",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/profiles/app_users.vue"),
            meta: {
              title: "Manage Ongoing Orders and Create New ones",
            },
          },

          {
            path: "app-notifications",
            name: "app_notifications",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/profiles/app_notifications.vue"),
            meta: {
              title: "Manage Ongoing Orders and Create New ones",
            },
          },

          {
            path: "device-codes",
            name: "device_codes",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/profiles/device_codes.vue"),
            meta: {
              title: "Manage Ongoing Orders and Create New ones",
            },
          },
        ],
      },
      {
        path: "users",
        name: "",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/Users/main.vue"),
        children: [
          {
            path: "",
            name: "user_management",
            props: true,
            beforeEnter: adminguard,
            component: () => import("@/views/app/Users/users.vue"),
            meta: {
              title: "Manage Ongoing Orders and Create New ones",
            },
          },
        ],
      },
      {
        path: "profile",
        name: "profile",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/profile/profile.vue"),
      },
    ],
  },
];

const mergedRoutes = routes;

export default mergedRoutes;
