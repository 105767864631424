export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        communications :'', 
        communication :'', 
    },
    
    getters : {
        communications: (state )=> state.communications,
        communication: (state )=> state.communication,
    },


    mutations : { 
        setcommunications :(state,communications ) => (state.communications = communications), 
        setcommunication :(state,communication ) => (state.communication = communication), 
    },

    actions : {

        async create_communication( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_communication')
            return new Promise((resolve, reject) => {
            axios.post("/admin/communications/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcommunications', response.data.res)
                toast.success("communication Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_communications( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_communications')
            return new Promise((resolve, reject) => {
            axios.post("/admin/communications?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcommunications', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_communication( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_communication' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/communications/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcommunication', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_communication( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_communication' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/communications/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcommunications', response.data.res)
                toast.success("communication Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async delete_communication( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_communication' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/communications/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcommunications', response.data.res)
                toast.success("communication Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}